import React, { useContext, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { QuizContext } from '../../../'
import { TUseNavigationParams } from '../../../@types'

const useQuizNavigation = ({
  steps,
  rootPath,
  onNextStep,
  onStepMount
}: TUseNavigationParams) => {
  const convertedRootPath = rootPath === '' ? '/' : rootPath
  const { getFieldByName, setTriedToSubmit, quiz } = useContext(QuizContext)
  const formRef = useRef<HTMLFormElement>(
    document.getElementById('quiz-form') as HTMLFormElement
  )
  const location = useLocation()
  const navigate = useNavigate()

  const stepsFromConfigWithRootPath = steps.map(step => {
    const newStep = {
      ...step,
      path: step.path !== '/' ? `${rootPath}${step.path}` : convertedRootPath,
    }

    return newStep
  })

  const currentStepIndex = stepsFromConfigWithRootPath.findIndex(
    step =>
      step.path === location.pathname || `${step.path}/` === location.pathname
  )
  const currentPropStep = stepsFromConfigWithRootPath[currentStepIndex]

  const currentResolvedPrevSteps = currentPropStep?.prevSteps?.(getFieldByName)
  const formatedPrevPath = currentResolvedPrevSteps === '/' ? ''
    : currentResolvedPrevSteps || ''

  const navigateToNextStep = (e: React.FormEvent) => {
    e.preventDefault()
    setTriedToSubmit(true)
    const DOMForm = formRef.current
    const stepIsValid = DOMForm.checkValidity()
    if (!stepIsValid) {
      return
    }

    const navigateTo = currentPropStep?.nextSteps
      ? currentPropStep.nextSteps(getFieldByName)
      : ''

    onNextStep?.()
    return navigate(`${rootPath}${navigateTo}`)
  }

  const navigateToPrevStep = () => {
    return navigate(`${rootPath}${formatedPrevPath}`)
  }

  const redirectIfNeeded = () => {
    const DOMForm = formRef.current

    const currentStepIsCompleted = Array.prototype.every.call(
      DOMForm.elements,
      element => {
        const currInputName = element.getAttribute('name')
        if (!currInputName) return true

        const currInputHasValue = !!quiz[currInputName]
        return !!currInputHasValue
      }
    )

    const pathnameEqualsPrev = location.pathname === `${rootPath}${formatedPrevPath}`

    if (!currentStepIsCompleted && !pathnameEqualsPrev) {
      navigate(`${rootPath}${formatedPrevPath}`, {
        replace: true,
      })
      navigate(0)
    }
  }

  useEffect(() => {
    onStepMount?.()
    setTriedToSubmit(false)
  }, [location, setTriedToSubmit])

  useEffect(() => {
    redirectIfNeeded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showPrevButton =
    currentPropStep?.prevSteps && !!currentPropStep?.prevSteps(getFieldByName)
  const showNextButton =
    currentPropStep?.nextSteps && !!currentPropStep?.nextSteps(getFieldByName)
  const defaultButtonProp = {
    className: '',
    id: '',
  }
  const prevButtonsProps = currentPropStep?.buttonProps?.prev
    ? currentPropStep.buttonProps.prev(getFieldByName)
    : defaultButtonProp
  const nextButtonsProps = currentPropStep?.buttonProps?.next
    ? currentPropStep.buttonProps.next(getFieldByName)
    : defaultButtonProp

  const buttons = {
    next: {
      show: showNextButton,
      ...nextButtonsProps,
    },
    prev: {
      show: showPrevButton,
      ...prevButtonsProps,
    },
  }

  return {
    navigateToNextStep,
    navigateToPrevStep,
    formRef,
    currentStepIndex,
    currentStep: currentPropStep,
    buttons,
  }
}

export default useQuizNavigation
