import { Container, styled } from '@escaletech/delta'

export const Navigation = styled(Container, {
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  paddingBottom: '$sm',
  paddingLeft: '$xs',
  paddingRight: '$xs',
  '@md': {
    position: 'absolute',
    paddingLeft: '$xxxxl',
    paddingRight: '$xxxxl',
  },
  div: {
    width: 'auto',
  },
})

export const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
})

export const FormInputs = styled('div', {
  display: 'flex',
})

export const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})
