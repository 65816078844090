import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import useQuizNavigation from './hooks/useQuizNavigation'
import * as S from './styles'
import { TQuizProps, TStep } from '../../@types'
import ProgressBar from '../ProgressBar'
import { history } from '../../config'

const QuizRenderer: React.FC<TQuizProps> = ({
  steps,
  navigation: { PrevComponent, NextComponent },
  progressBar,
  rootPath = '',
  onNextStep,
  onStepMount
}) => {
  const {
    buttons,
    currentStep,
    currentStepIndex,
    formRef,
    navigateToNextStep,
    navigateToPrevStep,
  } = useQuizNavigation({
    steps,
    rootPath,
    onNextStep,
    onStepMount
  })

  const Bar = (
    <ProgressBar
      progressColor={progressBar?.progressColor || ''}
      backgroundColor={progressBar?.backgroundColor || ''}
      currentStep={currentStep as TStep}
      currentStepIndex={currentStepIndex}
    />
  )

  const Navigation = (
    <S.Navigation
      css={{ justifyContent: !buttons.prev.show ? 'end' : 'space-between' }}
    >
      {buttons.prev.show && (
        <div onClick={navigateToPrevStep}>
          <PrevComponent
            className={buttons.prev.className}
            id={buttons.prev.id}
          />
        </div>
      )}
      {buttons.next.show && (
        <div onClick={navigateToNextStep}>
          <NextComponent
            className={buttons.next.className}
            id={buttons.next.id}
          />
        </div>
      )}
    </S.Navigation>
  )

  return (
    <S.Form ref={formRef} id="quiz-form" onSubmit={navigateToNextStep}>
      <S.InputContainer>
        <S.FormInputs>
          <Routes location={history.location}>
            {steps.map(({ path, Component, ...props }) => (
              <Route
                key={path}
                path={`${rootPath}${path}`}
                element={
                  <Component
                    navigateToNextStep={navigateToNextStep}
                    navigateToPrevStep={navigateToPrevStep}
                    ProgressBar={Bar}
                    Navigation={Navigation}
                    {...props}
                  />
                }
              />
            ))}
            <Route path="*" element={<Navigate to={rootPath} replace />} />
          </Routes>
        </S.FormInputs>
      </S.InputContainer>
    </S.Form>
  )
}

export default QuizRenderer
