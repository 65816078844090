import React, { createContext, useEffect } from 'react'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import QuizRenderer from './components/QuizRenderer'
import useQuiz from './components/QuizRenderer/hooks/useQuiz'
import { TQuizProps, TUseQuizReturnType } from './@types'
import { history } from './config'

export const QuizContext = createContext({} as TUseQuizReturnType)

const Quiz: React.FC<TQuizProps> = (QuizProps: TQuizProps) => {
  const useQuizProps = useQuiz(QuizProps.localStorageKey)

  useEffect(() => {
    QuizProps?.onLoad?.()
  }, [])

  return (
    <HistoryRouter history={history}>
      <QuizContext.Provider value={useQuizProps}>
        <QuizRenderer {...QuizProps} />
      </QuizContext.Provider>
    </HistoryRouter>
  )
}

export default Quiz
export * from './@types'
export { history } from './config'
