import { styled } from '@escaletech/delta'

export const ProgressBar = styled('div', {
  position: 'relative',
  width: '100%',
  height: '10px',
  marginBottom: '$sm',
  '@md': {
    marginBottom: '$lg',
  },
})

export const Progress = styled('div', {
  position: 'absolute',
  height: '10px',
  left: 0,
  right: 0,
  transition: 'width .3s ease-in-out',
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
})
