import { TQuiz } from '../../../@types'

export const setLocalStorageQuiz = (quiz: TQuiz, localStorageKey: string) =>
  localStorage.setItem(localStorageKey, JSON.stringify(quiz))

export const getLocalStorageQuiz = (localStorageKey: string) => {
  const localStorageQuiz = localStorage.getItem(localStorageKey)
  const result = !!localStorageQuiz
    ? (JSON.parse(localStorageQuiz) as TQuiz)
    : null

  return result
}
