import { useCallback, useEffect, useState } from 'react'
import {
  TFieldValue,
  TQuiz,
  TUseQuizReturnType,
  TValidator,
} from '../../../@types'
import { getLocalStorageQuiz, setLocalStorageQuiz } from '../helpers/session'

const useQuiz = (localStorageKey: string): TUseQuizReturnType => {
  const [triedToSubmit, setTriedToSubmit] = useState(false)
  const [quiz, setQuiz] = useState<TQuiz>(() => {
    const quizFromLocalStorage = getLocalStorageQuiz(localStorageKey)

    return quizFromLocalStorage ? quizFromLocalStorage : ({} as TQuiz)
  })

  useEffect(() => {
    setLocalStorageQuiz(quiz, localStorageKey)
  }, [quiz])

  const getFieldByName = useCallback(
    (fieldName: string) => {
      return quiz[fieldName] ? quiz[fieldName] : null
    },
    [quiz]
  )

  const setFieldValue = (fieldName: string, value: TFieldValue) => {
    const newQuiz = {
      ...quiz,
    }
    setQuiz({
      ...newQuiz,
      [fieldName]: value,
    })
  }

  const validateField = (name: string, validator: TValidator) => {
    const field = getFieldByName(name) || ''
    const fieldIsValid = validator.func(field)
    const DOMField = document.getElementsByName(name)[0] as HTMLInputElement
    DOMField.setCustomValidity(fieldIsValid ? '' : 'Campo inválido')

    return fieldIsValid
  }

  const deleteField = (fieldName: string) => {
    const newQuiz = {
      ...quiz,
    }

    delete newQuiz[fieldName]

    setQuiz(newQuiz)
  }

  return {
    quiz,
    triedToSubmit,
    setTriedToSubmit,
    setQuiz,
    setFieldValue,
    getFieldByName,
    deleteField,
    validateField,
  }
}

export default useQuiz
