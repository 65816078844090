import React from 'react'
import { TProgressBar } from '../../@types'
import * as S from './styles'

const ProgressBar: React.FC<TProgressBar> = ({
  progressColor,
  backgroundColor,
  currentStep,
}) => {
  const calculatedPercent = currentStep?.progress || 0

  return (
    <S.ProgressBar css={{ backgroundColor }}>
      <S.Progress
        css={{
          width: `${calculatedPercent}%`,
          backgroundColor: progressColor,
        }}
      />
    </S.ProgressBar>
  )
}

export default ProgressBar
